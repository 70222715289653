/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Subtitle, Text } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="css-11tdayw --style2 --full pb--60 pt--60" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mt--20">
              
              <Title className="title-box fs--72 title-box--invert" content={"+49 797 811 2X05<br>info@vase-stranky.com<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"[[UNIsecntioname12]]-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1200}}>
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--2" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":449}}>
              
              <Subtitle className="subtitle-box" content={"Craft Beer"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"Oeder Weg 22<br>info@vase-stranky.com<br>+49 797 811 2X05"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}